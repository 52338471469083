.info-page {
  code {
    font-family: monospace;
    .rounded-corners-tiny;
    background-color: var(--color-doc-code-background);
    color: var(--color-doc-code);
    padding: 0.2rem;
    border: 0 none;
  }
}
