.ltr-left(@offset) {
  left: @offset;
}

.ltr-right(@offset) {
  right: @offset;
}

.ltr-margin-right(@offset) {
  margin-right: @offset;
}

.ltr-margin-left(@offset) {
  margin-left: @offset;
}

.ltr-border-right(@offset) {
  border-right: @offset;
}

.ltr-border-left(@offset) {
  border-left: @offset;
}

.ltr-padding-right(@offset) {
  padding-right: @offset;
}

.ltr-padding-left(@offset) {
  padding-left: @offset;
}

.ltr-float-left() {
  float: left;
}

.ltr-float-right() {
  float: right;
}

.ltr-text-align-right() {
  text-align: right;
}

.ltr-rounded-left-corners(@radius) {
  border-radius: @radius 0 0 @radius;
}

.ltr-rounded-top-left-corners(@radius) {
  border-radius: @radius 0 0 0;
}

.ltr-rounded-bottom-left-corners(@radius) {
  border-radius: 0 0 0 @radius;
}

.ltr-rounded-right-corners(@radius) {
  border-radius: 0 @radius @radius 0;
}

.ltr-rounded-top-right-corners(@radius) {
  border-radius: 0 @radius 0 0;
}

.ltr-rounded-bottom-right-corners(@radius) {
  border-radius: 0 0 @radius 0;
}

.ltr-text-align-left() {
  text-align: left;
}

.ltr-border-left-width(@offset) {
  border-left-width: @offset;
}

.ltr-border-right-width(@offset) {
  border-right-width: @offset;
}

.ltr-transform() {
  transform: scale(1, 1);
}

@import "style.less";
