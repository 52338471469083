#main_index {
  margin-top: 26vh;
}

.index {
  text-align: center;

  .title {
    background: url('../img/searxng.png') no-repeat;
    min-height: 4rem;
    margin: 4rem auto;
    background-position: center;
    background-size: contain;
  }

  h1 {
    font-size: 4em;
    visibility: hidden;
  }

  #search,
  #search_header {
    margin: 0 auto;
    background: inherit;
    border: inherit;
    padding: 0;
    display: block;
  }

  .search_filters {
    display: block;
    margin: 1em 0;
  }

  .category label {
    padding: 6px 10px;
    border-bottom: initial !important;
  }
}

@media screen and (max-width: @tablet) {
  div.title {
    h1 {
      font-size: 1em;
    }
  }

  #main_index {
    margin-top: 6em;
  }
}
